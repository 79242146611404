import React from 'react';
import styled from 'styled-components';

import { PageTitle, PageWrapper } from '../components/pageComponents';
import { JoinUs } from '../components';

const JoinWrapper = styled.div`
  width: 85%;
`;

const JoinUsPage = () => {
  return (
    <PageWrapper>
      <PageTitle>FAÇA PARTE</PageTitle>
      <JoinWrapper>
        <JoinUs belowButtons={true}/>
      </JoinWrapper>
    </PageWrapper>
  );
};

export default JoinUsPage;
